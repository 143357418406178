import { configureStore } from '@reduxjs/toolkit';

import layoutReducer from './slices/layoutSlice';
import userReducer from './slices/userSlice';

const store = configureStore({
  reducer: {
    layout: layoutReducer,
    user: userReducer,
  },
});

export default store;

import React, {useState, useEffect} from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import {Check} from "@mui/icons-material";
import Hidden from "@mui/material/Hidden";

const StepperConnectorActive = (props) => {
    return (
        <StepConnector className="activeLine">
        </StepConnector>
    );
};

const StepperConnector = (props) => {
    return (
        <StepConnector>
            <div className="connectorLine" />
        </StepConnector>
    );
};


export default function StatusStepper({activeStep, isProcessing=false}) {
    let [currentLabel, setCurrentLabel] = useState('');
    let [futureStep, setFutureStep] = useState(-1);
    
    const labels = [
        "Banking Information",
        "Identity Verification",
        "Contract Review",
        "Get Funded",
    ];

    useEffect(() => {
        setCurrentLabel(labels[activeStep]);
        setFutureStep(activeStep + 1);
    }, [activeStep]);

    return (
        <div className="stepperRoot">
            <Stepper activeStep={futureStep} alternativeLabel connector={isProcessing ? <StepperConnectorActive/> : <StepperConnector/> }>
                    {labels.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={StepLabelIcon}>
                                <Hidden smDown>
                                    {label}
                                </Hidden>
                            </StepLabel>
                        </Step>
                    ))}
            </Stepper>
            {/* <Hidden smUp>
                <div>
                    <h3 className={classes.center}>{currentLabel}</h3>
                </div>
            </Hidden> */}
        </div>
    );

    function StepLabelIcon(props) {
        const {completed, icon, disabled} = props;
        console.log("icon", icon, "activeStep", activeStep)
        const isGray = (icon === futureStep) ? 'StepLabelIcon-root' : 'active';

        return (
            <div
            className={`StepLabelIcon-root ${disabled ? isGray : ''} ${completed && 'completed'}`}
                >
                {completed && (isProcessing || icon <= activeStep) ? <Check/> : String(props.icon)}
            </div>
        );
    }
}


import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
    flId: null,
    activeStep: 0,
    zip: null,
    email: '',
    loggedIn: false,
    loginPayload: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState: INIT_STATE,
    reducers: {
        saveFlId: (state, action) => {
            state.flId = action.payload;
        },
        saveDealId: (state, action) => {
            state.dealId = action.payload;
        },
        saveEmail: (state, action) => {
            state.email = action.payload;
        },
        saveActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        saveZip: (state, action) => {
            state.zip = action.payload;
        },
        saveLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        saveLoginPayload: (state, action) => {
            state.loginPayload = action.payload;
        },
    }
})

export const {
    saveFlId,
    saveDealId,
    saveEmail,
    saveZip,
    saveLoggedIn,
    saveLoginPayload,
    saveActiveStep,
} = userSlice.actions;

export default userSlice.reducer;

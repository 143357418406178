import React, { useEffect, useState } from 'react';
import { Grid, Button } from "@mui/material";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import HelloSign from 'hellosign-embedded';
import Disclaimer from './Disclaimer';
import CheckoutPleaseWait from './ContractWait';
import ContractCheckEmail from './ContractCheckEmail';
import { CONTRACT_SENT_STATE } from '../utils/constants';

export default function SignContract({
    updateVerification,
    applicationTrackerState,
    dropboxContractId,
}) {
    const [isFetching, setIsFetching] = useState(false);
    const disclaimer1 = 'We use Dropbox Sign for the transfer and collection of electronic signatures on our contracts.';
    const disclaimer2 = 'Be sure to read through your contract and familiarize yourself with the terms of your loan. ' +
        'Your Funding Consultant will be happy to answer any questions you may have.';
    const disclaimer3 = 'Note: Most customers receive their funds within 1 Business Day of signing their contract.';
    const [dropboxEmbeddedUrl, setDropboxEmbeddedUrl] = useState('');
    const [dropboxClientId, setDropboxClientId] = useState('');
    const [isContractInitError, setIsContractInitError] = useState(false);
    const [askToCheckInbox, setAskToCheckInbox] = useState(false);
    const [dropboxInitFinished, setDropboxInitFinished] = useState(false);
    const [dropboxClient, setDropboxClient] = useState(false);

    useEffect(() => {
        setDropboxInitFinished(false);
        if(dropboxClient) {
            dropboxClient.close();
        }

        if(applicationTrackerState === CONTRACT_SENT_STATE) {
            if(!dropboxContractId) {
                setAskToCheckInbox(true);
                setDropboxClientId('');
                setDropboxEmbeddedUrl('');
            } else {
                setIsFetching(true);
                setAskToCheckInbox(false);
                loadEmbeddedContract();
            }
        }
    }, [dropboxContractId]);

    function getDropboxInfo() {
        return fetch(`${process.env.REACT_APP_APPLY_API_URL}/v1/application/get-embedded-signing-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contractId: dropboxContractId,
            })
        });
    }

    function loadEmbeddedContract() {
        getDropboxInfo()
        .then(res => res.json())
        .then((data) => {
            setIsFetching(false);
            if(data.signUrl && data.clientId) {
                setDropboxEmbeddedUrl(data.signUrl);
                setDropboxClientId(data.clientId);
                setAskToCheckInbox(false);
                return;
            } else {
                // something went wrong
                onContractLoadError();
            }
        })
        .catch(() => {
            onContractLoadError();
        });
    }

    useEffect(() => {
        if(!askToCheckInbox && !dropboxInitFinished && dropboxEmbeddedUrl && dropboxClientId) {
            initDropboxClient();
        }
    }, [dropboxEmbeddedUrl, dropboxClientId]);

    function initDropboxClient() {
        const client = new HelloSign({
            clientId: dropboxClientId,
        });
        client.on('sign', (_) => {
            updateVerification();
        });
        client.on('error', () => {
            client.close();
        });
        client.on('cancel', () => {
            // get new signing url
            setDropboxClient(null);
            setIsFetching(true);
            setDropboxInitFinished(false);
            loadEmbeddedContract();
        });
        setDropboxInitFinished(true);
        setDropboxClient(client);
    }

    function beginContractSigning() {
        dropboxClient.open(dropboxEmbeddedUrl, {
            skipDomainVerification: true,
            testMode: process.env.NODE_ENV !== 'production',
        });
    }

    function onContractLoadError() {
        setIsContractInitError(true);
    }

    if(isContractInitError) {
        return (
            <CheckoutPleaseWait />
        )
    }

    return (
        <Grid className="root" container justifyContent="center">
            <Grid item xs={12} md={8}>
                {!askToCheckInbox ? (
                    <>
                        <h1 className="heading">Review & Sign Your Contract</h1>
                        <Button disabled={!dropboxInitFinished} className="checkoutButton" onClick={beginContractSigning}>Sign Contract</Button>
                        <Disclaimer>
                            <div className="disclaimerTitle">
                                <InfoIcon />
                                <p className="verifiedText">About Your Contract</p>
                            </div>
                            <p className="disclaimerText">{disclaimer1}</p>
                            <p className="disclaimerText">{disclaimer2}</p>
                            <p className="disclaimerText">{disclaimer3}</p>
                        </Disclaimer>
                    </>
                ) : null}
                {askToCheckInbox && !isFetching ? <ContractCheckEmail /> : null}
            </Grid>
        </Grid>
    )
}

import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
    appName: 'BriteCap',
    phoneNumber: '8887334889',
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState: INIT_STATE,
    reducers: {
        setAppName: (state, action) => {
            state.appName = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
    }
})

export const {setTheme, setLanguage} = layoutSlice.actions;

export default layoutSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Persona from 'persona';
import Disclaimer from './Disclaimer';

export default function VerifyIdentity({updateVerification, inquiryId, sessionToken}) {
    const [initFinished, setInitFinished] = useState(false);

    function handlePersona() {
        if (inquiryId && sessionToken) {
            const client = new Persona.Client({
                inquiryId: inquiryId,
                sessionToken: sessionToken,
                onLoad: () => client.open(),
                onComplete: (inquiryId, status, fields) => {
                    updateVerification();
                },
            });
        }
    }

    useEffect(() => {
        if(sessionToken && inquiryId) {
            setInitFinished(true);
        }
    }, [sessionToken, inquiryId]);

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
                <h1 className="verifyIdentityHeading">Verify Your Identity</h1>
                <Button disabled={!initFinished} className="checkoutButton" onClick={handlePersona}>Begin Verifying</Button>
            </Grid>
            <Disclaimer>
                <div className="disclaimerTitle">
                    <InfoIcon />
                    <p className="verifiedText">Get verified in just 5 seconds!</p>
                </div>
                <p className="disclaimerText">We take privacy seriously to protect you against fraud.{'\n'}Once you click the Begin Verifying button, you'll be{'\n'}prompted to enter information about yourself.</p>
            </Disclaimer>
        </Grid>
    )
}


import React, { useCallback, useState } from 'react';

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from 'react-plaid-link';
import { Button } from '@mui/material';

const PlaidOAuth = (props: any) => {
    const [token, setToken] = useState<string | null>(props.plaidToken);
    const [isPlaidSuccessInProgress, setIsPlaidSuccessInProgress] = useState<boolean>(false);
    const [isPlaidSuccessComplete, setIsPlaidSuccessComplete] = useState<boolean>(false);


    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

    const onSuccess = useCallback<typeof PlaidLinkOnSuccess>((publicToken: string, metadata: any) => {
        console.log(publicToken, metadata);
        props.setMetadata(metadata);
        props.setIsPlaidFinalizing(true);
    }, []);

    const onEvent = useCallback<typeof PlaidLinkOnEvent>((eventName: string, metadata: any) => {
        console.log(eventName, metadata);
    }, []);

    const onExit = useCallback<typeof PlaidLinkOnExit>((error: any , metadata: any) => {
        if (isOAuthRedirect && !isPlaidSuccessInProgress && !isPlaidSuccessComplete) {
            sessionStorage.removeItem('plaidLinkToken');
        }
        console.log(error, metadata);
    }, []);

    const config: typeof PlaidLinkOptions = {
        token,
        onSuccess,
        onEvent,
        onExit,
    };
    if (isOAuthRedirect) {
        config.receivedRedirectUri = window.location.href;
    }

    const {
        open,
        ready,
    } = usePlaidLink(config);

    React.useEffect(() => {
        // If OAuth redirect, instantly open link when it is ready instead of
        // making user click the button
        if (isOAuthRedirect && ready) {
            open();
        }
    }, [ready, open, isOAuthRedirect]);
    console.log(ready, open)
    // No need to render a button on OAuth redirect as link opens instantly
    return isOAuthRedirect ? (
        <></>
    ) : (
        <Button onClick={() => open()} disabled={!ready} className="plaidButton">
            Link Bank Account
        </Button>
    );
};

export default PlaidOAuth;

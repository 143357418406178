import React from "react";
import Grid from "@mui/material/Grid";
import bbb from "../img/bbb.gif";
import trustPilot from "../img/trust-pilot.gif";
import ssl from "../img/ssl-lock.svg";
import shield from "../img/shield-lock.webp";

function IconWidget() {
    return (
        <Grid style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <Grid container xs={6} className="paper" style={{alignItems: "center", maxWidth: "600px"}}>
                <Grid item xs={3} style={{justifyContent: 'center'}}>
                    <img src={shield} alt="secure"
                            style={{width: '30%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                </Grid>
                <Grid item xs={3} style={{justifyContent: 'center'}}>
                    <img src={ssl} alt="ssl"
                            style={{width: '55%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                </Grid>
                <Grid item xs={3} style={{justifyContent: 'center'}}>
                    <img src={bbb} alt="A+ bbb"
                            style={{width: '75%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                </Grid>
                <Grid item xs={3} style={{justifyContent: 'center'}}>
                    <img src={trustPilot} alt="5* TrustPilot"
                            style={{width: '75%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default IconWidget;

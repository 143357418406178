import React from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {formatPhonePeriod} from "../helpers";
import logo from '../img/flLogo.svg';
import britecap_logo from '../img/britecap-logo.webp';
import {useSelector} from "react-redux";

export default function Header(props) {
    const appName = useSelector(state => state.layout.appName);
    const loggedIn = useSelector(state => state.user.loggedIn);
    const phoneNumber = process.env.REACT_APP_HELP_NUMBER;

    return (
        <AppBar className="header" position="static">
            <Toolbar>
                <a className="logo" href={"https://www." + appName + ".com/"}>
                    <img src={appName === 'BriteCap' ? britecap_logo : logo} height={appName === 'BriteCap' ? "58" : ""} width={appName === 'BriteCap' ? '182' : ''} alt="logo"/>
                </a>
                {loggedIn && phoneNumber ? <div className="callUsWrapper">
                    <h2 className="callUsHeading">CALL US:
                        <a className="callUsLink" href={`tel:${phoneNumber}`}> {formatPhonePeriod(phoneNumber)}</a>
                    </h2>
                </div> : null}
            </Toolbar>
        </AppBar>
    );
}

export function formatPhoneNumber(phoneNumberString) {
    var x = phoneNumberString.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}

export function isEmailValid(emailString) {
    return emailString.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

export function formatPhonePeriod(phone) {
    return phone.substring(0, 3) + "." + phone.substring(3, 6) + "." + phone.substring(6, 10)
}

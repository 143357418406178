import { Button, Grid, Hidden } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logBrokerEvent } from '../helpers/brokerEventLog';
import SideBar from './SideBar';
import IconWidget from './IconWidget';

const allowedStages = [
    'Offers Presented',
    'Offer Selected',
    'Contract Sent',
    'Contract Signed',
    'Ready To Fund',
];

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    roundingMode: 'floor',
});

function getCents(num) {
    const cents = Math.floor((Number.parseFloat(num) % 1).toFixed(2) * 100);
    return cents.toString().padStart(2, '0');
}

function FundingDetail({ number, label }) {
    return (
        <>
            <p className="offerDetail">{dollarUS.format(number)}.<span className="sup">{getCents(number)}</span></p>
            <p className="offerLabel">{label}</p>
        </>
    )
}

export default function OfferSummary({ beginCheckout, loginPayload }) {
    const { offer, stage } = loginPayload;
    const loanType = 'Term Loan';
    const renewalEligible = 'Renewal Eligible';
    const frequency = offer ? offer.recurringPaymentType : '';
    const dealId = useSelector(state => state.user.dealId);
    const [isLoading, setIsLoading] = useState(false);
    const [sendContractByEmail, setSendContractByEmail] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const showError = (message) => enqueueSnackbar(message, { variant: 'error' });

    const onCheckoutClick = () => {
        if (loginPayload && loginPayload.stage === 'Offers Presented') {
            setIsLoading(true);
            fetch(
                process.env.REACT_APP_APPLY_API_URL + '/v1/application/accept-offer',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        dealId: dealId,
                        sendContractByEmail: sendContractByEmail,
                    }),
                },
            ).then((response) => response.json())
                .then((response) => {
                    setIsLoading(false);
                    if (response.Status === 'offer accepted') {
                        logBrokerEvent(dealId, 'offer_accepted');
                        return true;
                    }
                    return Promise.reject();
                })
                .then(() => beginCheckout())
                .catch((e) => {
                    setIsLoading(false);
                    showError('Something went wrong.');
                });
        } else {
            beginCheckout();
        }
    }

    const getButtonTitle = () => {
        if (isLoading) {
            return 'Loading';
        } else if (loginPayload && loginPayload.stage === 'Offers Presented') {
            return 'Checkout';
        } else {
            return 'Continue';
        }
    }

    return (
        <Grid container style={{ paddingTop: "2%" }} justifyContent="center">
            <Grid item xs={12} sm={6} md={6}>
                {offer && allowedStages.includes(stage) ? (
                    <div className="offerSummary">
                        <Grid item md={12} xs={12} className="offerSummaryRoot">
                            <h1 className="offerSummaryHeading">About Your Funding</h1>
                            <p className="paragraph">Your selected offer:</p>
                            <div className="summaryTable">
                                <div className="tableRow">
                                    <div className="tableCell">
                                        <FundingDetail number={offer.fundingAmount} label={loanType} />
                                    </div>
                                    <div className="tableCell">
                                        <FundingDetail number={offer.repaymentAmount} label={'Repayment Amount'} />
                                    </div>
                                    <div className="tableCell">
                                        <FundingDetail number={offer.achFixedAmount} label={`${frequency} Auto-Payments`} />
                                    </div>
                                </div>
                                <div className="tableRow">
                                    <div className="tableCellDesc">
                                        <p className="offerDesc">
                                            {offer.numberOfPayments} {frequency.toLowerCase()} payments over {offer.fundingTerm} months
                                        </p>
                                    </div>
                                    <div className="tableCellDesc">
                                        <p className="offerDesc">Fixed Rate</p>
                                    </div>
                                    <div className="tableCellDesc">
                                        <p className="offerDesc">
                                            {renewalEligible}
                                        </p>
                                    </div>
                                </div>
                                <div className="tableRow">
                                    <div className="tableCell checkoutBtnCell">
                                        <Button disabled={isLoading} className="checkoutButton" onClick={onCheckoutClick}>{getButtonTitle()}</Button>
                                    </div>
                                </div>
                            </div>

                        </Grid>
                    </div>) : (
                    <div className="notReadyWrapper">
                        <h1 className="heading notReadyHeading">Offer is not ready yet.</h1>
                    </div>
                )}
                <IconWidget />
            </Grid>
            <Hidden mdDown>
                <Grid item xs={4} md={4}>
                    <SideBar />
                </Grid>
            </Hidden>
        </Grid>
    )
}

import React, { useState } from 'react';
import {CircularProgress, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import './dropzone.css';

export const DropZoneLayout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
        <div >
            <div {...dropzoneProps}>
                {files.length < maxFiles && input}
            </div>
            {files.length > 0 && submitButton}
            {previews}
        </div>
    )
}

export default function DocumentUpload({
    isOpen,
    onClose,
    onSubmit,
    maxFiles = Number.MAX_SAFE_INTEGER,
    minFiles = 1,
    title,
    description,
    dealId,
    fluid,
    docCategory,
}) {
    const [isUploading, setIsUploading] = useState(false);

    const onPopupClose = () => {
        if(onClose) {
            onClose();
        }
    }

    function uploadDocuments(documents) {
        setIsUploading(true);
        const uploads = documents.map((document) => {
            return fetch(`${process.env.REACT_APP_APPLY_API_URL}/v2/upload/${dealId}/${fluid}/${docCategory}/${document.file.name}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                body: document.file,
            });
        });
        Promise.all(uploads)
            .then(() => {
                if(onSubmit) {
                    onSubmit();
                }
                setIsUploading(false);
            })
            .catch(() => {
                // Something went wrong
                setIsUploading(false);
            });
        return true;
    }

    return(
        <>
            <Modal open={isOpen && !isUploading} className="modal upload-document-modal">
                <div className="document-modal">
                <IconButton
                    aria-label="close"
                    className="close-button"
                    onClick={onPopupClose}
                    size="large">
                        <CloseIcon />
                    </IconButton>
                    <div className="modal-body">
                        <h2 className="verification-title">{title}</h2>
                        <p className="verification-desc">{description}</p>
                        <Dropzone
                            accept=".pdf, image/jpeg, image/png, image/bmp, image/tiff"
                            LayoutComponent={DropZoneLayout}
                            onSubmit={uploadDocuments}
                            className="dzu-submitButtonContainer dzu-submitButton dzu-previewContainer dzu-inputLabel dzu-dropzone dzu-inputLabelWithFiles"
                            inputContent="Drag & Drop Files Here - Or Click Here to Upload"
                            inputWithFilesContent={"Drag & Drop Files Here - Or Click Here to Upload"}
                            maxSizeBytes={10485760}
                            maxFiles={maxFiles}
                            submitButtonDisabled={(files => files.length < minFiles)}
                        />
                    </div>
                </div>
            </Modal>
            <Modal open={isOpen && isUploading} className="modal upload-document-modal">
                <div class="document-modal">
                    <CircularProgress className="circularProgress" size={80} />
                    <h2 style={{paddingTop: "10px", textAlign: "center"}}>Uploading</h2>
                </div>
            </Modal>
        </>
    )
}

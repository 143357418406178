import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from "@mui/material";
import { useSelector } from 'react-redux';
import PlaidOAuth from "./PlaidOAuth";
import 'react-dropzone-uploader/dist/styles.css';
import './dropzone.css';
import DocumentUpload from './DocumentUpload';

export default function MtdBankStatement({
    updateVerification,
    setInstitutionName, 
    setIsPlaidConnected,
    isPlaidInitFinished,
    plaidLinkToken,
    appNumber,
    docCategory,
}) {
    const email = useSelector(state => state.user.email);
    const dealId = useSelector(state => state.user.dealId);
    const fluid = useSelector(state => state.user.flId);
    const [isMtdPopupOpen, setIsMtdPopupOpen] = useState(false);
    const [isPlaidFinalizing, setIsPlaidFinalizing] = useState(false);
    const [metaData, setMetadata] = useState(null);

    function linkToPlaid(plaid) {
        fetch(process.env.REACT_APP_APPLY_API_URL + '/v1/connect_bank', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fluid: fluid,
                dealId: dealId,
                email: email,
                public_token: plaid.public_token,
                institution_id: plaid.institution_id,
                institution_name: plaid.institution_name,
                is_mtd: true,
            })
        })
        .then(res => {
            // logApplicationTrackerGTMEvent('plaid'); # TODO
            setInstitutionName(plaid.institution_name);
            setIsPlaidConnected(true);
            updateVerification();
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function handlePlaidSuccess() {
        let plaid = {
            public_token: metaData.public_token,
            institution_id: metaData.institution.institution_id,
            institution_name: metaData.institution.name,
        };
        linkToPlaid(plaid);
    }

    useEffect(() => {
        if (isPlaidFinalizing) {
            handlePlaidSuccess();
        }
    }, [isPlaidFinalizing]);

    const openMtdPopup = () => {
        setIsMtdPopupOpen(true);
    }

    const onPopupClose = () => {
        setIsMtdPopupOpen(false);
    }

    const onUploadSuccess = () => {
        triggerProcessing(); // this assumes that customer has provided all docs and starts processing
        setIsMtdPopupOpen(false);
        updateVerification();
        // @TODO check if these events are required
        // logHSEvent('at_verify_upload_document_button', email);
        // logApplicationTrackerGTMEvent('upload');
    }

    function triggerProcessing() {
        fetch(process.env.REACT_APP_APPLY_API_URL + '/v1/ocrolus/' + appNumber, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        });
        // No error handling - if error does occur, the files will auto process in 10 minutes
    }

    return (
        <Grid className="root" container justifyContent="center">
            <Grid item xs={12} md={8}>
                <h1 className="heading">Banking Information</h1>
                <p className="infoText disclaimer">Prior to transferring funds, we need to{'\n'}review your month to date banking activity.</p>
                { !isPlaidFinalizing && isPlaidInitFinished ?
                <PlaidOAuth plaidToken={plaidLinkToken} setIsPlaidFinalizing={setIsPlaidFinalizing} setMetadata={setMetadata} isMtd={true}/>
                : null}
                {!isPlaidInitFinished || isPlaidFinalizing ? <CircularProgress className="circularProgress" size={100} /> : null}
                <Grid xs={12} sm={12} md={12} item>
                    <p className="infoText">Unable to link your bank?<button className="alternative-option-btn" onClick={openMtdPopup}>Click Here</button></p>
                </Grid>
                {isMtdPopupOpen ? <DocumentUpload
                    isOpen={isMtdPopupOpen}
                    onClose={onPopupClose}
                    onSubmit={onUploadSuccess}
                    minFiles={1}
                    title={'Latest (MTD) Bank Statement'}
                    description={'Upload your month-to-date bank statements'}
                    dealId={dealId}
                    fluid={fluid}
                    docCategory={docCategory}
                /> : null}
            </Grid>
        </Grid>
    )    
}

import React from "react";

import {LinearProgress} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function LoginForm(props) {

    const {errorMsg, lastFour, email, isLoggingIn, handleChange, handleLogin} = props;

    function enterKeyPress(e) {
        if (e.keyCode === 13) {
            handleLogin();
        }
    }

    return (
        <>
            <form onChange={handleChange}>
                <FormControl variant="outlined" className="formControl">
                    <TextField
                        autoFocus
                        label="Last 4 digits of your Social Security Number"
                        type="password"
                        inputProps={{ inputMode: 'numeric' }}
                        variant="outlined"
                        id="lastFour"
                        name="lastFour"
                        value={lastFour}
                        helperText={errorMsg.lastFour}
                        error={errorMsg.lastFour !== ''}
                        onKeyDown={enterKeyPress}
                    />
                </FormControl>
                <FormControl variant="outlined" className="formControl">
                    <TextField
                        label="Email"
                        variant="outlined"
                        id="email"
                        name="email"
                        value={email}
                        helperText={errorMsg.email}
                        error={errorMsg.email !== ''}
                        disabled={true}
                    />
                </FormControl>
                {isLoggingIn ? <LinearProgress/> : null}
                <Button className="loginButton" onClick={handleLogin}>Log In</Button>
            </form>
            {errorMsg.login !== '' ?
                <div className="loginErrorMessage">
                    {errorMsg.login}
                </div>
                : null}
        </>
    );
}

import './App.css';
import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import {useDispatch} from 'react-redux';
import Header from "./components/Header";
import Footer from "./components/Footer";
import CheckoutLanding from './containers/CheckoutLanding';
import {
    saveFlId,
    saveDealId,
    saveEmail,
    saveZip
} from './store/slices/userSlice';
import {
    StylesProvider,
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from '@mui/material/styles';

const theme = createTheme();

export default function App(props) {

    const dispatch = useDispatch();

    function setCheckoutParams(params) {
        const dealId = params.get('dealId');
        const email = params.get('email');
        if (dealId) {
            dispatch(saveDealId(dealId));
            sessionStorage.setItem('deal_id', dealId);
        }
        if (email) {
            dispatch(saveEmail(email));
            sessionStorage.setItem('email', email);
        }
    }

    function setZip(params) {
        const zip = params.get('zip');
        if (zip) {
            dispatch(saveZip(zip));
        }
    }

    function setFlId(params) {
        const flId = params.get('accountId') || params.get('fl_id');
        if (flId) {
            dispatch(saveFlId(flId));
            sessionStorage.setItem('sf_id', flId);
            sessionStorage.setItem('fluid', flId);
        }
    }

    function saveQueryString(params) {
        const query = {};
        for (const pair of params.entries()) {
            query[pair[0]] = pair[1];
        }
        sessionStorage.setItem('query', JSON.stringify(query));
        return query;
    }

    function setQueryParameters() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setCheckoutParams(params);
        setFlId(params);
        saveQueryString(params);
        setZip(params);
    }

    useEffect(() => {
        setQueryParameters();
    }, []);

    return (
        <Router> 
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Header />
                    <Routes>
                        <Route path='/' element={<CheckoutLanding />} />
                    </Routes>
                    <Footer />
                </ThemeProvider>
            </StyledEngineProvider>
        </Router>
    );  
}

import React, {useEffect, useState} from 'react';
import { Grid } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dropzone from "react-dropzone-uploader";
import {DropZoneLayout} from "./DocumentUpload";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import check from "../img/new_check.webp";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Disclaimer from './Disclaimer';
import { useSelector } from 'react-redux';

export default function VoidedCheck({fileCategory, updateVerification, loginPayload}) {
    const dealId = useSelector(state => state.user.dealId);
    const fluid = useSelector(state => state.user.flId);
    const [isFetching, setIsFetching] = useState(false);
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountNumberConfirmation, setAccountNumberConfirmation] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [isRoutingNumberFound, setIsRoutingNumberFound] = useState(true);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [formTries, setFormTries] = useState(0);
    const [canShowForm, setCanShowForm] = useState(true);
    const [showError, setShowError] = useState(false);

    const title = 'Banking Information';
    const topText = 'Enter your routing and account number for your primary business account or upload a voided check';

    function handleUploadVoidedCheckSubmit(files) {
        files = files.map(obj => obj.file);
        uploadDocuments(files);
    }

    function uploadDocuments(documents) {
        setIsFetching(true);
        console.log('uploadDocuments');
        const promises = documents.map(async document => {
            return fetch(process.env.REACT_APP_APPLY_API_URL + '/v2/upload/' + dealId + '/' + fluid + '/' + fileCategory + '/' + document.name, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
                body: document,
            });
        });
        Promise.all(promises)
            .then(() => {
                setIsFetching(false);
                updateVerification();
                return true;
            })
            .catch((e) => {
                console.log(`something went wrong: ${e}`);
                setIsFetching(false);
            });
        return true;
    }

    function submitBankDetails() {
        setIsFetching(true);
        fetch(process.env.REACT_APP_APPLY_API_URL + '/v1/application/add-bank-account-info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dealId: dealId,
                routingNumber: routingNumber,
                accountNumber: accountNumberConfirmation,
                bankHolderInfo: {
                    applicationUid: loginPayload.applicationUid,
                    firstName: loginPayload.firstName,
                    lastName: loginPayload.lastName,
                    legalBusinessName: loginPayload.company.legalName,
                },
            })
        })
        .then(async (response) => {
            let jsonBody = await response.json();
            console.log(`Testing: `, response);
            setIsFetching(false);
            if(response.status !== 200) {
                setShowError(true);
                if(formTries < 2) {
                    setFormTries(formTries+1);
                } else {
                    setIsFormVisible(false);
                    setCanShowForm(false);
                }
            } else {
                if (!jsonBody.success) {
                    if(jsonBody.routingNumber) {
                        setIsRoutingNumberFound(false);
                    } else {
                        setShowError(true);
                    }

                    if(formTries < 2) {
                        setFormTries(formTries+1);
                    } else {
                        setIsFormVisible(false);
                        setCanShowForm(false);
                    }
                } else {
                    console.log('Testing');
                    // submission was successful
                    // complete the verification
                    updateVerification();
                }
            }
        })
        .catch((e) => {
            setIsFetching(false);
        });
    }

    function handleRoutingChange(event) {
        let sanitizedValue = event.target.value.toString().replace(/\D/g, '');
        setRoutingNumber(sanitizedValue);
        setIsRoutingNumberFound(true);
    }

    function handleAccountChange(event) {
        let name = event.target.name;
        let sanitizedValue = event.target.value.toString().replace(/\D/g, '');
        if (name === 'accountNumber') {
            setAccountNumber(sanitizedValue);
        } else if (name === 'confirmAccountNumber') {
            setAccountNumberConfirmation(sanitizedValue);
        }
    }

    function doAccountNumbersMatch() {
        return accountNumber === accountNumberConfirmation;
    }

    function handleClickHere() {
        setIsFormVisible(!isFormVisible);
        setCanShowForm(true);
    }

    function isRoutingNumberValid(number) {
        return (number == null || (number.length === 9));
    }

    function isAccountNumberValid(number) {
        return (number == null || (number.length >= 9 && number.length <= 20));
    }

    useEffect(() => {
        let routingNumberFull = routingNumber.length === 9;
        let accountNumberFull = accountNumber.length >= 9 && accountNumber.length <= 20;
        let accountNumberConfirmationFull = accountNumberConfirmation.length >= 9 && accountNumberConfirmation.length <= 20;
        let isAllInputValid = routingNumberFull
                                && accountNumberFull
                                && accountNumberConfirmationFull
                                && doAccountNumbersMatch();
        setIsFormComplete(isAllInputValid);

    }, [routingNumber, accountNumber, accountNumberConfirmation]);

    function confirmAccountHelpText() {
        if(accountNumberConfirmation) {
            if(!doAccountNumbersMatch()) {
                return 'Account Numbers do not match';
            }
        }
        return '';
    }

    const formInputGroup = (
        <div className='root'>
            <h2 className="heading">{title}</h2>
            <div className="infoText disclaimer">{topText}</div>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={4} className="bankDetailFormWrapper">
                    <FormControl className="checkFormControl">
                        <FormLabel className="form-label">Routing Number</FormLabel>
                        <TextField
                            type="text"
                            value={routingNumber}
                            variant="outlined"
                            name="routingNumber"
                            onChange={handleRoutingChange}
                            inputProps={{minLength: 9, maxLength: 9, inputMode: 'numeric', pattern: '[0-9]*', className: 'textField'}}
                            helperText={routingNumber && !isRoutingNumberValid(routingNumber) ? '9 Digit Number Required' : ""}
                            error={routingNumber && !isRoutingNumberValid(routingNumber)}
                            size="small"
                        />
                    </FormControl>
                    {!isRoutingNumberFound ?
                        <FormControl className="checkFormControl">
                            <FormLabel className="error-label">
                                The Routing Number appears to be invalid. Please re-enter.
                            </FormLabel>
                        </FormControl>
                    : false}
                    <FormControl className="checkFormControl">
                        <FormLabel className="form-label">Bank Account Number</FormLabel>
                        <TextField
                            type="text"
                            placeholder=""
                            value={accountNumber}
                            variant="outlined"
                            name="accountNumber"
                            onChange={handleAccountChange}
                            inputProps={{minLength: 9, maxLength: 20, inputMode: 'numeric', pattern: '[0-9]*', className: 'textField'}}
                            error={accountNumber && !isAccountNumberValid(accountNumber)}
                            size="small"
                        />
                    </FormControl>
                    <FormControl className="checkFormControl">
                        <FormLabel className="form-label">Confirm Bank Account Number</FormLabel>
                        <TextField
                            type="text"
                            placeholder=""
                            value={accountNumberConfirmation}
                            variant="outlined"
                            name="confirmAccountNumber"
                            onChange={handleAccountChange}
                            inputProps={{minLength: 9, maxLength: 20, inputMode: 'numeric', pattern: '[0-9]*', className: 'textField'}}
                            helperText={confirmAccountHelpText()}
                            error={confirmAccountHelpText()}
                            size="small"
                        />
                    </FormControl>
                    <FormControl className="checkFormControl">
                            {showError ?
                                <FormLabel className="error-label">
                                    Your banking information could not be validated. Please try again.
                                </FormLabel>
                            : false}
                    </FormControl>
                    {!isFetching ?
                        <Button disabled={!isFormComplete || isFetching} className="check-submit-button" onClick={submitBankDetails.bind(this)}>
                            Submit
                        </Button>
                    : <CircularProgress className="circularProgress"/>}
                </Grid>
                <Grid item xs={12} md={6} className="check-image">
                    <img src={check} alt="check"/>
                </Grid>
            </Grid>
        </div>
    );

    const dragAndDrop = (
        <Grid className="checkDragDropWrapper">
            { !canShowForm || !isFormVisible ? <>
                <h2 className="heading">{title}</h2>
                {canShowForm
                  ? <p className="infoText disclaimer text-center">Please submit a voided check.</p>
                  : <p className="infoText disclaimer text-center">Your banking information could not be validated. Please submit a voided check.</p>
                }
            </> : null}
            {!isFetching ?
                <Dropzone
                    accept=".pdf, image/jpeg, image/png, image/bmp, image/tiff"
                    LayoutComponent={DropZoneLayout}
                    onSubmit={handleUploadVoidedCheckSubmit}
                    className="dzu-submitButtonContainer dzu-submitButton dzu-previewContainer dzu-inputLabel dzu-dropzone dzu-inputLabelWithFiles"
                    inputContent="Drag & Drop Files Here - Or Click Here to Upload"
                    inputWithFilesContent={"Drag & Drop Files Here - Or Click Here to Upload"}
                    maxSizeBytes={10485760}
                    maxFiles={1}
                    submitButtonDisabled={(files => files.length === 0)}
                />
            : <CircularProgress className="circularProgress" size={50} />}
        </Grid>
    );

    const formToggle = <span className="alternative-option-btn" onClick={handleClickHere}>Click here</span>;

    return (
        <>
            <Grid container justifyContent="center">
                {isFormVisible && canShowForm ? formInputGroup : dragAndDrop}
                <Grid item xs={12}>
                    <p className="infoText text-center">{isFormVisible ? 'Or to upload a voided check' : 'Or to enter bank details'} {formToggle}.</p>
                </Grid>
            </Grid>
            <Disclaimer>
                <div className="disclaimerTitle">
                    <InfoIcon />
                    <p className="verifiedText">Why we need your banking information?</p>
                </div>
                <p className="disclaimerText">Your Banking information is used to verify your account ownership and to facilitate electronic transactions.</p>
            </Disclaimer>
        </>
    );
}

export const logBrokerEvent = (dealId, activity) => {
    return fetch(
        `${process.env.REACT_APP_APPLY_API_URL}/v1/broker/checkout/event/${dealId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_agent: navigator.userAgent,
                activity: activity,
            }),
        }
    )
    .catch((e) => {
        console.log(`error while logging event:`, e);
    })
}

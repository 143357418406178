import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import contractImg from '../img/case-study.webp';

export default function CheckoutPleaseWait({waitTime = 30}) {
    let timerRef = React.useRef();
    const [showCopy, setShowCopy] = useState(false);
    
    useEffect(() => {
        // set timer to turn on copy
        timerRef.current = setTimeout(() => {
            setShowCopy(true);
        }, waitTime * 1000);
        return () => {
            clearTimeout(timerRef.current);
        }
    }, []);

    if(!showCopy) {
        return (
            <div className="loadingWrapper">
                <CircularProgress size={150} className="circularProgress" />
                <p className="infoText">Please wait while we generate your contract.</p>
            </div>
        )
    }
    
    return (
        <div className="checkoutWaitWrapper">
            <img alt="contract-img" src={contractImg} width="100px" />
            <p className="infoText">Thank you for your patience as we work to generate your contract.</p>
            <p className="infoText">We apologize for the delay. We need to complete a few more steps before your contract will be ready.</p>
            <p className="infoText">We will notify you as soon as your contract is ready for review.</p>
        </div>
    ) 
}

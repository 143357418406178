import { Grid, IconButton, Paper } from '@mui/material';
import React, {useState} from 'react';
import CloseIcon from "@mui/icons-material/Close";

export default function Disclaimer({children}) {
    const [isVisible, setIsFormVisible] = useState(true);
    const handleClose = () => setIsFormVisible(false);

    return <>
        {isVisible ?
            <Paper className="infoPaper">
                <IconButton
                    aria-label="close"
                    className="closeButton"
                    onClick={handleClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
                {children}
            </Paper>
        : null}
    </>;
}

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserLogin from "../components/UserLogin";
import { saveLoggedIn, saveActiveStep } from '../store/slices/userSlice';
import OnlineCheckout from "./OnlineCheckout";
import OfferSummary from "../components/OfferSummary";

const applicationFlowStates = {
    postSubmitted: 1 << 0,
    postSubmittedStuck: 1 << 1,
    placement: 1 << 2,
    declinedNoPrelim: 1 << 3,
    declinedPrelim: 1 << 4,
    prelim: 1 << 5,
    bankStatementsAttached: 1 << 6,
    appCompleted: 1 << 7,
    offersReady: 1 << 8,
    offersPresented: 1 << 9,
    offerSelected: 1 << 10,
    contractSent: 1 << 11,
    contractSigned: 1 << 12,
    readyToFund: 1 << 13,
    funded: 1 << 14,
    withdrawn: 1 << 15,
    expired: 1 << 16,
}

const offerSummaryStates = applicationFlowStates.postSubmitted |applicationFlowStates.postSubmittedStuck |applicationFlowStates.placement |applicationFlowStates.declinedNoPrelim |applicationFlowStates.declinedPrelim |applicationFlowStates.prelim |applicationFlowStates.bankStatementsAttached | applicationFlowStates.appCompleted | applicationFlowStates.offersReady | applicationFlowStates.offersPresented | applicationFlowStates.offerSelected | applicationFlowStates.contractSent | applicationFlowStates.contractSigned | applicationFlowStates.readyToFund | applicationFlowStates.funded;
const checkoutStates = applicationFlowStates.offersPresented | applicationFlowStates.offerSelected | applicationFlowStates.contractSent | applicationFlowStates.contractSigned | applicationFlowStates.readyToFund | applicationFlowStates.funded;

export default function CheckoutLanding() {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const dealId = useSelector(state => state.user.dealId);
    const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
    const [isOfferSummaryVisible, setIsOfferSummaryVisible] = useState(false);
    const [applicationToken, setApplicationToken] = useState(sessionStorage['application_token']);
    const [personaSessionToken, setPersonaSessionToken] = useState(sessionStorage['personaToken'] || '');
    const persistedState = sessionStorage.getItem('login_payload') ? JSON.parse(sessionStorage.getItem('login_payload')) : {};
    const dispatch = useDispatch();

    let [websocket, setWebsocket] = useState(null);
    const [isWebsocketConnected, setIsWebsocketConnected] = useState(false);
    const [applicationTrackerPayload, setApplicationTrackerPayload] = useState({});

    const onSigninSuccess = (response) => {
        setShowSummary(true);
        dispatch(saveLoggedIn(true));
        setIsLoggedIn(true);
        setApplicationToken(response.applicationToken);
        handleApplicationTrackerPayload(response);
        connectWebsocket(response.applicationToken);
    }

    function connectWebsocket(applicationTrackerToken) {
        let wsInstance = new WebSocket(process.env.REACT_APP_WEBSOCKET_HOST);

        wsInstance.onmessage = function (event) {
            try {
                const payload = JSON.parse(event.data);
                if (payload) {
                    console.log('subscription message received');
                    setApplicationTrackerPayload(payload);
                    handleApplicationTrackerPayload(payload);
                }
            } catch (err) {
                console.log(err);
                setIsLoggedIn(false);
            }
        };

        wsInstance.onopen = (event) => {
            let subscriptionPayload = {
                messageType: 'subscribe',
                applicationTrackerToken: applicationTrackerToken,
                dealId: dealId,
                isBroker: true,
            }
            wsInstance.send(JSON.stringify(subscriptionPayload));
            setIsWebsocketConnected(true);
        };

        wsInstance.onclose = function (event) {
            console.log('subscription connection closed');
            setIsWebsocketConnected(false);
        }

        wsInstance.onerror = function () {
            console.log('socket connection errored');
            setIsWebsocketConnected(false);
            wsInstance.close();
        }

        setWebsocket(wsInstance);
    }

    useEffect(() => {
        const { applicationTrackerToken } = persistedState;
        if (!isWebsocketConnected && applicationTrackerToken) {
            connectWebsocket(applicationTrackerToken);
        }
        handleApplicationTrackerPayload(persistedState);
    }, [isWebsocketConnected]);

    const handleApplicationTrackerPayload = (payload) => {
        const { applicationTrackerState, persona } = payload;

        const isCheckoutVisibleStatus = (checkoutStates & applicationFlowStates[applicationTrackerState]);
        setIsCheckoutVisible(isCheckoutVisibleStatus);

        const isOfferSummaryVisible = (offerSummaryStates & applicationFlowStates[applicationTrackerState]);
        setIsOfferSummaryVisible(isOfferSummaryVisible);

        if (!personaSessionToken &&
            ((persona && persona.inquiryId) || (payload.persona && payload.persona.inquiryId))
        ) {
            let personaInquiryId = persona.inquiryId || payload.persona.inquiryId;
            getPersonaSessionToken(personaInquiryId);
        }
    }

    function beginCheckout() {
        dispatch(saveActiveStep(1));
        setShowSummary(false);
        setIsCheckoutVisible(true);
    }

    function getPersonaSessionToken(inquiryId) {
        fetch(`${process.env.REACT_APP_APPLY_API_URL}/v1/application/get-persona-session-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inquiryId: inquiryId,
                dealId: dealId,
            }),
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            }
            return false;
        })
        .then((response) => {
            if(response && response.success) {
                setPersonaSessionToken(response.token);
                sessionStorage.setItem('personaToken', response.token);
                if(response.inquiry_id) {
                    setApplicationTrackerPayload({
                        ...applicationTrackerPayload,
                        persona: {
                            inquiryId: response.inquiry_id,
                        }
                    });
                }
            }
        });
    }

    return (
        <div className="checkoutLandingRoot">
            {!isLoggedIn ? (
                <UserLogin
                    applicationToken={applicationToken}
                    setApplicationToken={setApplicationToken}
                    setIsLoggedIn={setIsLoggedIn}
                    onSigninSuccess={onSigninSuccess}
                />
            ) : null}
            {isLoggedIn && showSummary && isOfferSummaryVisible ? (
                <OfferSummary
                    beginCheckout={beginCheckout}
                    loginPayload={persistedState}
                />
            ) : null}
            {isLoggedIn && !showSummary && isCheckoutVisible ? (
                <OnlineCheckout
                    loginPayload={persistedState}
                    setIsLoggedIn={setIsLoggedIn}
                    personaSessionToken={personaSessionToken}
                    applicationTrackerPayload={applicationTrackerPayload}
                />
            ) : null}
        </div>
    )
}

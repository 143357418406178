import React from 'react';
import {useSelector} from 'react-redux';

export default function Footer() {
    const year = new Date().getFullYear();
    const appName = useSelector(state => state.layout.appName);
    return (
        <footer className="footer">
            <div className="footerText">
                &copy; {year} { appName } Financial, LLC. All rights reserved.
                <br/><br/>
                All small business loans offered by { appName } Financial, LLC are provided pursuant to
                California Finance Lender license #6039614. In certain states, loans may not be available at
                all or may be limited in availability depending upon certain borrower and loan attributes. In
                certain states where loans are not available, we may offer a purchase of future receivables.
                We do not currently offer any products in Nevada, North Dakota, Rhode Island, South Dakota,
                and Vermont.
                <br/><br/>
                <a href={'https://www.' + appName + '.com/terms-and-conditions/'} style={{color: '#999999'}}>
                    { appName } Terms & Conditions & Privacy Policy
                </a>
            </div>
        </footer>
    );

}

import { Grid } from '@mui/material';
import AdHoc from './AdHoc';

export default function FundingConfirmation({
    verifications,
    updateVerification,
    dealId,
    fluid,
    setAdhocDoLater,
    adhocDoLater,
    contractSigned,
    contractReady,
    hasPendingAdhoc,
}) {

    function getFundingMessage() {
        return `We're now processing your funding
                request. Once completed, your funds will
                be transferred to your account within one
                business day.`;
    }

    return (
        <Grid container justifyContent="center">
            <Grid justifyContent="center" item xs={12} sm={4} md={4} className="fundingWrapper">
                <h3 className="heading">Congratulations!</h3>
                {!hasPendingAdhoc ? 
                    <>
                        <p className="infoText">{getFundingMessage()}</p>
                    </>
                : null}
            </Grid>
            {hasPendingAdhoc ? 
                <Grid justifyContent="center" item xs={12} sm={12} md={12} className="fundingWrapper">
                    <p className="infoText">Your funding agreement has been signed and is now in pending the following item(s)</p>
                    <AdHoc
                        verifications={verifications}
                        updateVerification={updateVerification}
                        dealId={dealId}
                        fluid={fluid}
                        setAdhocDoLater={setAdhocDoLater}
                        adhocDoLater={adhocDoLater}
                        contractSigned={contractSigned}
                        contractReady={contractReady}
                    />
                </Grid>
            : null}
        </Grid>
    )
}

import React, {useEffect, useState, useRef} from 'react';
import IconWidget from '../components/IconWidget';
import { CircularProgress, Grid } from '@mui/material';
import StatusStepper from '../components/Stepper';
import VerifyIdentity from '../components/VerifyIdentity';
import AdHoc from '../components/AdHoc';
import MtdBankStatement from '../components/MtdBankStatement';
import SignContract from '../components/SignContract';
import FundingConfirmation from '../components/FundingConfirmation';
import VoidedCheck from '../components/VoidedCheck';
import { useSelector } from 'react-redux';
import CheckoutPleaseWait from '../components/ContractWait';
import { CONTRACT_SENT_STATE, CONTRACT_SIGNED_STATE } from '../utils/constants';

const PERSONA = 'Persona';
const MTD = 'MTD';
const VOIDED_CHECK = 'VOIDED_CHECK';
const CONTRACT = 'CONTRACT';
const FUNDING = 'FUNDING';
const ADHOC = 'ADHOC';
const QUESTION_BASED = 'questionBased';
const CONTRACT_TO_BE_CREATED = 'CONTRACT_TO_BE_CREATED';
const WAITING_UW_REVIEW = 'WAITING_UW_REVIEW';
const STATUS_CODE = 'status_code';
const verificationItems = {
    [PERSONA]: 'Persona',
    [MTD]: 'MTDBankStatement',
    [VOIDED_CHECK]: 'BankAccount',
}

const checkoutSteps = {
    [MTD]: 0,
    [VOIDED_CHECK]: 0,
    [WAITING_UW_REVIEW]: 1,
    [PERSONA]: 1,
    [CONTRACT]: 2,
    [FUNDING]: 3,
}

const PLAID_LINK_TTL = 20; // minutes

export default function OnlineCheckout({ loginPayload, setIsLoggedIn, personaSessionToken, applicationTrackerPayload }) {
    const email = useSelector(state => state.user.email);
    const dealId = useSelector(state => state.user.dealId);
    const flId = useSelector(state => state.user.flId);
    const [isPlaidInitFinished, setIsPlaidInitFinished] = useState(false);
    const [plaidLinkToken, setPlaidLinkToken] = useState('');
    const [institutionName, setInstitutionName] = useState();
    const [plaidItemConnectionStatus, setPlaidItemConnectionStatus] = useState();
    const [isPlaidConnected, setIsPlaidConnected] = useState();
    const [plaidLinkTokenTimestamp, setPlaidLinkTokenTimestamp] = useState();
    const [activeStep, setActiveStep] = useState(null);
    const [sequence, setSequence] = useState(null);
    const [inquiryId, setInquiryId] = useState('');
    const [verifications, setVerifications] = useState(null);
    const [completedSteps, setCompletedSteps] = useState(sessionStorage.getItem('completed_steps') ? JSON.parse(sessionStorage.getItem('completed_steps')) : []);
    const persistedState = sessionStorage.getItem('login_payload') ? JSON.parse(sessionStorage.getItem('login_payload')) : {};
    const {appNumber} = persistedState;
    const [applicationTrackerState, setApplicationTrackerState] = useState(loginPayload.applicationTrackerState);
    const [dropboxContractId, setDropboxContractId] = useState(loginPayload.dropboxContractId);
    const [adhocDoLater, setAdhocDoLater] = useState(false);
    const [contractSigned, setContractSigned] = useState(false);

    function updateCheckout(response) {
        if (response.tokenExpired) {
            setIsLoggedIn(false);
            return true;
        }
        setApplicationTrackerState(response.applicationTrackerState);

        if(response.persona && response.persona.inquiryId) {
            setInquiryId(response.persona.inquiryId);
        }
        // incase offer regenerates
        // we want to update dropboxContractId
        // based on the value flow will be decided 
        setDropboxContractId(response.dropboxContractId);
    }

    useEffect(() => {
        updateCheckout(applicationTrackerPayload);
    }, [applicationTrackerPayload]);

    function updateVerification() {
        setVerifications(null);
        setCompletedSteps([...completedSteps, sequence]);
        getVerificationStatuses();
    }

    function onContractSigned() {
        // move to next stage
        // websocket update for contractSigned
        // will keep the FUNDING active
        setSequence(FUNDING);
        setActiveStep(checkoutSteps[FUNDING]);
        setContractSigned(true);
    }

    function skipAdhoc() {
        if(!adhocDoLater) {
            setAdhocDoLater(true);
            calculateStep();
        }
    }

    useEffect(() => {
        sessionStorage.setItem('completed_steps', JSON.stringify(completedSteps));
    }, [completedSteps]);

    useEffect(() => {
        if(verifications) {
            calculateStep();
        }
    }, [verifications, completedSteps, adhocDoLater, personaSessionToken]);


    useEffect(() => {
        getVerificationStatuses();
    }, [applicationTrackerState, inquiryId]);

    function isVerificationPending(todo, isUpNextItem = true) {
        if(isUpNextItem) {
            return verifications[todo] && verifications[todo][STATUS_CODE] === 0 && !completedSteps.includes(todo);
        }
        return !completedSteps.includes(todo);
    }

    function isContractSentStage() {
        return applicationTrackerState === CONTRACT_SENT_STATE;
    }

    function isContractSignedStage() {
        return applicationTrackerState === CONTRACT_SIGNED_STATE;
    }

    function hasPendingAdhoc() {
        for (const item in verifications) {
            if(verifications[item]['status_code'] === 0) {
                if(verifications[item]['adhoc_notes'] && (verifications[item]['document_required'] || verifications[item]['verification_type'] === QUESTION_BASED)) {
                    return true;
                }
            }
        }
        return false;
    }

    function ShowProcessingMessage() {
       return(
            <div className="checkoutWaitWrapper uwReview">
                <p>Thank you for your patience as we process your information.</p>
                <p>We apologize for the delay. We're finishing up a few final steps.</p>
                <p>We will notify you as soon as everything is ready.</p>
            </div>
       );
    }

    function isCheckoutReady() {
        return inquiryId && personaSessionToken;
    }

    function calculateStep() {
        if(isVerificationPending(verificationItems[VOIDED_CHECK])) {
            setActiveStep(checkoutSteps[VOIDED_CHECK]);
            setSequence(VOIDED_CHECK);
            return;
        } else if(isVerificationPending(verificationItems[MTD])) {
            setActiveStep(checkoutSteps[MTD]);
            setSequence(MTD);
            return;
        }
        else if(!isCheckoutReady() && !verifications[verificationItems[PERSONA]]) {
            setActiveStep(checkoutSteps[WAITING_UW_REVIEW]);
            setSequence(WAITING_UW_REVIEW);
            return;
        }
        else if(isVerificationPending(verificationItems[PERSONA])) {
            setActiveStep(checkoutSteps[PERSONA]);
            setSequence(PERSONA);
            return;
        }
        else if(!isContractSignedStage() && !adhocDoLater && hasPendingAdhoc()) {
            setActiveStep(checkoutSteps[CONTRACT]);
            setSequence(ADHOC);
            return;
        }

        if(isContractSentStage()) {
            setActiveStep(checkoutSteps[CONTRACT]);
            setSequence(CONTRACT);
        } else if(isContractSignedStage()) {
            setActiveStep(checkoutSteps[FUNDING]);
            setSequence(FUNDING);
        } else {
            // Default wait widget as nothing is pending
            // assuming all todo are done and contract 
            // generation is pending
            setSequence(CONTRACT_TO_BE_CREATED);
            setActiveStep(checkoutSteps[CONTRACT]);
        }
    }

    async function getVerificationStatuses() {
        let verification_statuses_response = await fetch(process.env.REACT_APP_APPLY_API_URL + '/v1/application/get-verification-statuses', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                applicationNumber: appNumber,
                isUpNext: false,
            })
        });
        let response = await verification_statuses_response.json();
        console.log('verifications: ', response);
        setVerifications(response);
    }

    useEffect(() => {
        console.log('brokerCheckout.plaidLinkTokenUseEffect');
        let active = true;
        async function runInitBank() {
            let init_plaid_link_response = await fetch(process.env.REACT_APP_APPLY_API_URL + '/v2/init_bank', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    dealId: dealId,
                    redirect: window.location.origin + window.location.pathname,
                })
            });
            let response = await init_plaid_link_response.json();
            if (active) {
                sessionStorage.setItem('plaidLinkToken', response.plaidLinkToken);
                sessionStorage.setItem('plaidLinkTokenTimestamp', new Date().getTime().toString());
                sessionStorage.setItem('plaidInstitutionName', response.institutionName);
                sessionStorage.setItem('plaidItemConnectionStatus', response.plaidItemConnectionStatus);
                sessionStorage.setItem('isPlaidConnected', response.isPlaidConnected);
                setPlaidStates();
                setIsPlaidInitFinished(true);
                setPlaidLinkTokenTimestamp(sessionStorage.getItem('plaidLinkTokenTimestamp'));
            }
        }
        if (isPlaidInitFinished) {
            console.log('we are done generating Plaid Link');
            return;
        }
        if (plaidLinkToken && plaidLinkToken !== 'undefined' && plaidLinkToken !== 'null' && isPlaidLinkTokenValid()) {
            console.log('we have a plaidLinkToken and it is still valid');
            setIsPlaidInitFinished(true);
            return;
        }
        if (sessionStorage['plaidLinkToken'] && sessionStorage['plaidLinkToken'] !== 'undefined' && plaidLinkToken !== 'null' && isPlaidLinkTokenValid()) {
            setPlaidStates();
        } else {
            console.time('Running init-bank')
            runInitBank();
        }
        return () => {active = false};
    }, [plaidLinkToken]);

    function setPlaidStates() {
        console.log('brokerCheckout.setPlaidStates');
        setPlaidLinkToken(sessionStorage['plaidLinkToken']);
        if (sessionStorage.getItem('plaidInstitutionName')) {
            setInstitutionName(sessionStorage['plaidInstitutionName']);
        }
        if (sessionStorage.getItem('plaidItemConnectionStatus')) {
            setPlaidItemConnectionStatus(sessionStorage['plaidItemConnectionStatus']);
        }
        if (sessionStorage.getItem('isPlaidConnected')) {
            setIsPlaidConnected(sessionStorage['isPlaidConnected']);
        }
    }

    function isPlaidLinkTokenValid() {
        console.log('brokerCheckout.isPlaidLinkTokenValid');
        let now = new Date().getTime();
        var differenceInMs = now - parseInt(plaidLinkTokenTimestamp);
        var differenceInMinutes = Math.round(((differenceInMs % 86400000) % 3600000) / 60000); // minutes
        if (differenceInMinutes > PLAID_LINK_TTL) {
            return false;
        }
        return true;
    }

    function getCheckoutComponent() {
        switch(sequence) {
            case VOIDED_CHECK:
                return (
                    <VoidedCheck
                        updateVerification={updateVerification}
                        fileCategory={verificationItems[VOIDED_CHECK]}
                        loginPayload={loginPayload}
                    />
                )
            case MTD:
                return (
                    <MtdBankStatement
                        updateVerification={updateVerification}
                        setInstitutionName={setInstitutionName}
                        setIsPlaidConnected={setIsPlaidConnected}
                        isPlaidInitFinished={isPlaidConnected}
                        plaidLinkToken={plaidLinkToken}
                        appNumber={persistedState.appNumber}
                        docCategory={verificationItems[MTD]}
                    />
                )
            case WAITING_UW_REVIEW:
                return <ShowProcessingMessage />;
            case PERSONA:
                return (
                    <VerifyIdentity
                        updateVerification={updateVerification}
                        inquiryId={inquiryId}
                        sessionToken={personaSessionToken}
                    />
                )
            case CONTRACT:
                return (
                    <SignContract
                        updateVerification={onContractSigned}
                        applicationTrackerState={applicationTrackerState}
                        dropboxContractId={dropboxContractId}
                    />
                )
            case ADHOC:
                return (
                    <AdHoc
                        verifications={verifications}
                        updateVerification={updateVerification}
                        dealId={dealId}
                        fluid={flId}
                        setAdhocDoLater={skipAdhoc}
                        adhocDoLater={adhocDoLater}
                        contractSigned={contractSigned || isContractSignedStage()}
                        contractReady={isContractSentStage()}
                        getVerificationStatuses={getVerificationStatuses}
                    />
                )
            case FUNDING:
                return (
                    <FundingConfirmation
                        verifications={verifications}
                        updateVerification={updateVerification}
                        dealId={dealId}
                        fluid={flId}
                        setAdhocDoLater={skipAdhoc}
                        adhocDoLater={adhocDoLater}
                        contractSigned={contractSigned || isContractSignedStage()}
                        contractReady={isContractSentStage()}
                        hasPendingAdhoc={hasPendingAdhoc()}
                    />
                )
            case CONTRACT_TO_BE_CREATED:
                return (
                    <CheckoutPleaseWait />
                )
            default:
                return '';
        }
    }

    return (
        <div className="onlineCheckoutRoot">
            <h1 className="title">Checkout</h1>
            <StatusStepper activeStep={activeStep} />
            <Grid container xs={12} md={12} className="componentWrapper">
                {verifications ? getCheckoutComponent() : <CircularProgress className="circularProgress" size={160}/>}
            </Grid>
            <IconWidget />
        </div>
    )
}

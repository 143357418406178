import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { formatPhoneNumber, isEmailValid } from "../helpers";
import LoginForm from "./LoginForm";
import FormControl from "@mui/material/FormControl";
import { CircularProgress, Grid, Hidden } from "@mui/material";
import { useDispatch } from 'react-redux';
import { saveLoginPayload } from "../store/slices/userSlice";
import SideBar from "./SideBar";
import IconWidget from "./IconWidget";

export default function UserLogin({ applicationToken, onSigninSuccess }) {
    const user = useSelector(state => state.user);

    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isCheckingLoginStatus, setIsCheckingLoginStatus] = useState(true);
    const [email, setEmail] = useState(user.email);
    const [lastFour, setLastFour] = useState('');
    const [errorMsg, setErrorMsg] = useState({
        email: '',
        lastFour: '',
        businessZip: '',
        login: ''
    });
    const [loginAttempts, setLoginAttempts] = useState(0);
    const savedDealId = useSelector(state => state.user.dealId);
    const fluid = useSelector(state => state.user.flId);
    const appName = useSelector(state => state.layout.appName);
    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "Checkout Login - " + appName;
    }, []);

    useEffect(() => {
        if (!applicationToken) {
            setIsCheckingLoginStatus(false);
        }

        if (isCheckingLoginStatus && email && applicationToken) {
            loginAPI(applicationToken);
        }
    }, [email, applicationToken])

    useEffect(() => {
        setEmail(user.email);
    }, [user.email])

    function handleChange(event) {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'lastFour') {
            value = value.toString().replace(/\D/g, '');
            if (value.length <= 4) {
                setLastFour(value);
            }
        }
    }

    function handleLogin() {
        let emailError = '';
        let lastFourError = '';
        if (!isEmailValid(email) || email.length === 0) {
            emailError = 'Invalid Email Address';
        }
        if (lastFour.length !== 4) {
            lastFourError = 'Invalid Last 4 SSN';
        }
        setErrorMsg({
            email: emailError,
            lastFour: lastFourError,
            login: '',
        });
        if (emailError === '' && lastFourError === '') {
            loginAPI(lastFour);
        }
    }

    async function loginAPI(lastFour) {
        setIsLoggingIn(true);
        let response = await fetch(process.env.REACT_APP_APPLY_API_URL + '/v1/broker/checkout/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                lastFour: lastFour,
                fluid,
                dealId: savedDealId,
                attempts: loginAttempts + 1,
            })
        });
        if (response.status !== 200) {
            setLastFour('');
            sessionStorage.removeItem('application_token');
            sessionStorage.removeItem('login_payload');
            setErrorMsg({
                email: '',
                lastFour: '',
                login: 'Invalid login'
            });
            setLoginAttempts(loginAttempts + 1);
        } else {
            let res = await response.json();
            if (res.success) {
                sessionStorage.setItem('login_payload', JSON.stringify(res));
                sessionStorage.setItem('application_token', res.applicationToken);
                onSigninSuccess(res);
                dispatch(saveLoginPayload(res));
                console.log('setting logged in to true');
            } else {
                setErrorMsg({
                    email: '',
                    lastFour: '',
                    login: <>Please contact your Representative {res.brokerPhone && res.brokerName ? <>from {res.brokerName} <a className="phoneLink" href={`tel:${res.brokerPhone}`}> {formatPhoneNumber(res.brokerPhone)}</a></> : null}</>
                });
            }
        }
        setIsCheckingLoginStatus(false);
        setIsLoggingIn(false);
    }

    function displayLoading() {
        return (
            <FormControl container style={{ width: '100%', padding: '20px 0' }}>
                <FormControl>
                    <CircularProgress
                        className="circularProgress"
                        size={150} />
                </FormControl>
            </FormControl>
        );
    }

    return (
        <Grid container style={{ paddingTop: "2%" }} justifyContent="center">
            <Grid item xs={12} sm={6} md={6}>
                <div className="userLogin">
                    {!isCheckingLoginStatus ? <>
                        <h1 className="userLoginHeading">Login</h1>
                        <p className="signInParagraph">Sign in by verifying your information</p>
                        <LoginForm errorMsg={errorMsg} lastFour={lastFour} fluid={fluid} email={email}
                            isLoggingIn={isLoggingIn} handleChange={handleChange}
                            handleLogin={handleLogin} />
                    </> : displayLoading()}
                </div>
                <IconWidget />
            </Grid>
            <Hidden mdDown>
                <Grid item xs={4} md={4}>
                    <SideBar />
                </Grid>
            </Hidden>
        </Grid>
    );

}

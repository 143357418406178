import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import DocumentVerification from './DocumentVerification';
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

export default function AdHoc({ verifications, updateVerification, dealId, fluid, adHocDoLater, setAdhocDoLater, contractSigned, contractReady, getVerificationStatuses }) {
    const QUESTION_BASED = 'questionBased';
    const textInput = React.createRef();
    const [description, setDescription] = useState('');
    const [instruction, setInstruction] = useState('');
    const [adHocHeading, setAdHocHeading] = useState('');
    const [verificationType, setVerificationType] = useState('');
    const [verificationUid, setVerificationUid] = useState('');
    const [category, setCategory] = useState('');
    const [formValue, setFormValue] = useState('');
    const [textInputFocus, setTextInputFocus] = useState(false);
    const [isSubmtting, setIsSubmtting] = useState(false);

    function calculateNextItem() {
        let item = {}, docCategory = '';
        for (const category in verifications) {
            if (verifications[category]['status_code'] === 0) {
                if (verifications[category]['adhoc_notes'] && (verifications[category]['document_required'] || verifications[category]['verification_type'] === QUESTION_BASED)) {
                    item = verifications[category];
                    docCategory = category;
                    break;
                }
            }
        }

        setVerificationType(item['verification_type']);
        setVerificationUid(item['verification_uid']);

        let heading = item['verification_type'] === QUESTION_BASED
            ? "Additional Information is required prior to depositing funds"
            : "Additional documentation is required prior to depositing funds";
        setAdHocHeading(heading);
        setDescription(item['verification_description']);
        setInstruction(item['verification_instruction'])
        setCategory(docCategory);
    }

    useEffect(() => {
        if (verifications) {
            calculateNextItem();
        }
    }, [verifications]);

    function onUploadComplete() {
        updateVerification();
    }

    const handleChange = (e) => {
        setFormValue(e.target.value);
    };

    async function submitAnswer() {
        if (formValue) {
            try {
                setIsSubmtting(true);
                const response = await fetch(`${process.env.REACT_APP_APPLY_API_URL}/v1/deal/${dealId}/verifications/answer`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        verificationUid: verificationUid,
                        answer: formValue,
                    }),
                });

                if (response.status !== 200) {
                    console.error('There was an error', response.status);
                } else {
                    setFormValue('');
                    getVerificationStatuses();
                    setIsSubmtting(false);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        } else {
            if (textInput.current) {
                textInput.current.focus();
            }
            setTextInputFocus(true);
        }
    }

    return (
        <Grid container justifyContent="center">
            <Grid xs={12} md={4} sm={4} className="adhocWrapper">
                {!adHocDoLater && !contractSigned ?
                    <h1 className="adHocHeading">{adHocHeading}</h1>
                    : null}
                <p className="infoText disclaimer">{description}</p>
                <p className="infoText disclaimer">{instruction}</p>
                {
                    (verificationType === QUESTION_BASED) ?
                        (isSubmtting ? <CircularProgress className="circularProgress" /> :
                            <div className="verification-form-container">
                                <TextField
                                    label={category}
                                    type="text"
                                    variant="outlined"
                                    id="textInput"
                                    value={formValue}
                                    name="textValue"
                                    onBlur={() => setTextInputFocus(false)}
                                    onFocus={() => setTextInputFocus(true)}
                                    onChange={handleChange}
                                    helperText={textInputFocus && !formValue ? 'Input Required' : ""}
                                    error={textInputFocus & !formValue}
                                    multiline
                                    minRows={4}
                                />
                                <Button
                                    className="checkoutButton verification-submit-btn"
                                    id="verify-text"
                                    onClick={submitAnswer}
                                >
                                    Submit
                                </Button>
                            </div>)
                        :
                        <DocumentVerification
                            onSubmit={onUploadComplete.bind(this)}
                            minFiles={1}
                            description={description}
                            dealId={dealId}
                            fluid={fluid}
                            docCategory={category}
                        />
                }
                {!contractSigned ?
                    <Button
                        className={`checkoutButton skipAdhocButton ${verificationType === QUESTION_BASED ? 'w-70' : 'w-100'} `}
                        onClick={() => setAdhocDoLater()}
                    >
                        I'll do it later
                    </Button>
                    : null}
            </Grid>
        </Grid>
    )
}

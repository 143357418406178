import React, { useState } from 'react';
import { CircularProgress, Grid } from "@mui/material";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import './dropzone.css';

const DropZoneLayout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
        <div >
            <div {...dropzoneProps}>
                {files.length < maxFiles && input}
            </div>
            {files.length > 0 && submitButton}
            {previews}
        </div>
    )
}

export default function DocumentVerification({
    onSubmit,
    maxFiles = 10,
    minFiles = 1,
    dealId,
    fluid,
    docCategory,
}) {
    const [isUploading, setIsUploading] = useState(false);

    function uploadDocuments(documents) {
        setIsUploading(true);
        const uploads = documents.map((document) => {
            return fetch(`${process.env.REACT_APP_APPLY_API_URL}/v2/upload/${dealId}/${fluid}/${docCategory}/${document.file.name}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                body: document.file,
            });
        });
        Promise.all(uploads)
            .then(() => {
                if(onSubmit) {
                    onSubmit();
                }
                setIsUploading(false);
            })
            .catch(() => {
                // Something went wrong
                setIsUploading(false);
            });
        return true;
    }
    
    function renderProgress() {
        return (
            <Grid item xs={12} style={{margin: '10px auto'}}>
                <CircularProgress className="circularProgress" size={40} />
                <h4 style={{margin: 0, padding: 0, textAlign: "center", fontSize: '1.25rem'}}>Uploading</h4>
            </Grid>
        )
    }

    function renderDropZone() {
        return (
            <Dropzone
                accept=".pdf, image/jpeg, image/png, image/bmp, image/tiff"
                LayoutComponent={DropZoneLayout}
                onSubmit={uploadDocuments}
                className="dzu-submitButtonContainer dzu-submitButton dzu-previewContainer dzu-inputLabel dzu-dropzone dzu-inputLabelWithFiles"
                inputContent="Drag & Drop Files Here - Or Click Here to Upload"
                inputWithFilesContent={"Drag & Drop Files Here - Or Click Here to Upload"}
                maxSizeBytes={10485760}
                maxFiles={maxFiles}
                submitButtonDisabled={(files => files.length < minFiles)}
            />
        )
    }

    return (
        <>
            {!isUploading ? renderDropZone() : renderProgress()}
        </>
    )
}

import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function ContractCheckEmail() {
    const copyItems = [
        "Exciting news! Your contract is ready and waiting for you in your email inbox. Take a moment to review all the important details we've sent your way.",
        "Need assistance or have questions? Our team is here to help! Feel free to reach out if you need any clarification or further information.",
        "Don't delay, your next steps await in your inbox!",
    ];
    return (
        <Grid item xs={12}>
            <Typography variant='h1' className="heading">
                Check Your Inbox for Your Contract!
            </Typography>
            {copyItems.map((item, index) => (
                <Typography key={index} className="disclaimerText text-center">
                    {item}
                </Typography>
                ))}
        </Grid>
    )
}
